import React from 'react';
import {Link} from 'react-router-dom';

import Logo from './Logo';
import Phone from './Phone';
import Cart from '../cart/Cart';


export default function Head() {
    return <div className="container">
        <div className="row">
            <div className="col-lg-4 col-md-6 text-center text-lg-left">
                <Link to="/"><Logo/></Link>
            </div>
            <div className="col-lg-4 col-md-6 text-center align-self-center">
                <Phone/>
            </div>
            <div className="col-lg-4 col-md-12 align-self-center text-center text-lg-right">
                <Cart/>
            </div>
        </div>
    </div>
}