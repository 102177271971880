const dev = {
    API_URL: 'http://localhost:8000/api'
};

const prod = {
    API_URL: 'https://api.vkusnyashki-ot-oli.ru/api'
};

const config = process.env.NODE_ENV === 'development'
  ? dev
  : prod;

export default {
  ...config
};