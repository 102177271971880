import '../sass/index.sass';

import React, {useEffect, useState} from 'react';

import {instance} from '../api';

import Slider from '../components/common/Slider';
import Head from '../components/common/Head';
import Tail from '../components/common/Tail';
import CatList from '../components/cat/CatList';
import ReviewSlider from '../components/reviews/ReviewSlider';


export default function HomePage() {
    const initialState = {
        catList: []
    };
    const [state, setState] = useState(initialState);
    const {catList} = state;

    useEffect(() => {
        const getCatList = async () => {
            const {data} = await instance.get(`/cat/`);
            setState({catList: data})
        };
        getCatList();
    }, []);

    return (
        <React.Fragment>
            <Head/>
            <Slider/>
            <CatList catList={catList}/>
            <ReviewSlider/>
            <Tail/>
        </React.Fragment>
    );
}
