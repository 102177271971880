import React, {Fragment, useContext} from 'react';

import ReviewsContext from '../../context/ReviewsContext';
import no_ava from '../../img/no-avatar.png';
import Stars from './Stars';
import {formatDate} from '../../utils/date';

export default function ReviewList() {
    const {reviews, actions} = useContext(ReviewsContext);

    const moreLoad = reviews && reviews.next && <div className="row"><div className="col text-center">
        <button className="btn btn-primary" onClick={() => actions.getReviews(reviews.next)}>Ещё отзывы</button>
    </div></div>;

    return <Fragment>
        {reviews && reviews.results && reviews.results.map(item=>
        <div className="row mb-5" key={item.id}>
            <div className="col-sm-2 text-center">
                <img src={item.photo.thumbnail ? item.photo.thumbnail : no_ava } alt="" className="round-thumbnail-big"/>
            </div>
            <div className="col-sm-10 text-center text-sm-left">
                <p className="reviewer">Автор отзыва: {item.author} | Дата отзыва: {formatDate(item.date_published)}</p>
                <p><Stars rate={item.rate}/></p>
                <p>{item.review}</p>
            </div>
        </div>)}
        {moreLoad}
    </Fragment>;
};


