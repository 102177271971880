import React from 'react';

export default function OrderCountInput({item, changeCartCount}) {
    const changeHandler = e => {
        let count = e.target.value.slice(0, 2);
        if (count < 1) count = 1;
        changeCartCount(item.id, item.taste, count)
    };

    return <input className="form-control" type="number" min="1" max="99" value={item.count}
                  onChange={changeHandler}/>
}
