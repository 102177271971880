import React, {Fragment, useContext, useState} from 'react';

import GlobalContext from '../../context/GlobalContext';


export default function SelectPrice({item, closeAfterAdd = false}) {
    const [{selectedPrice}, setState] = useState({selectedPrice: item.prices.length ? item.prices[0] : 0});
    const [taste, setTaste] = useState(item.tastes.length ? item.tastes[0] : '');
    const {actions} = useContext(GlobalContext);
    const {closeModalDetail, addToCart} = actions;

    const selectPriceHandler = e => {
        const priceId = parseInt(e.target.value);
        const newSelectedPrice = item.prices.find(x => x.id === priceId);
        setState({selectedPrice: newSelectedPrice});
    };

    const selectTasteHandler = e => {
        setTaste(e.target.value);
    };

    const buyHandler = () => {
        addToCart({...selectedPrice, taste, item_name: item.name});
        !closeAfterAdd || closeModalDetail();
    };

    return <Fragment>
        {item.tastes.length > 0 && <div className="row mb-2">
            <div className="col">
                <select name="price" className='form-control' onChange={selectTasteHandler}>
                    {
                        item.tastes.map(taste =>
                            <option value={taste} key={taste}>
                                {taste}
                            </option>)
                    }
                </select>
            </div>
        </div>}

        <div className="row">
            <div className="col-lg-5 col-12 pr-sm-0 mb-3">
                <select name="price" className='form-control' onChange={selectPriceHandler}>
                    {
                        item.prices.map(price =>
                            <option value={price.id} key={price.id}>
                                {price.name}
                            </option>)
                    }
                </select>
            </div>
            <div className="col-lg-3 col-6 pr-sm-0">
                <span className='price'>{selectedPrice && parseInt(selectedPrice.price)} руб</span>
            </div>
            <div className="col-lg-4 col-6 text-right pl-sm-0">
                <button className='btn btn-primary' onClick={buyHandler}>Купить</button>
            </div>
        </div>
    </Fragment>
}