import React from 'react';

import fill_star from '../../img/rate-star.svg';
import empty_star from '../../img/rate-star-empty.svg';


const Stars = ({rate}) => <span className="text-center">
    {[1, 2, 3, 4, 5].map(i => <img src={i < rate + 1 ? fill_star : empty_star} key={i} alt={rate}/>)}
</span>;


export default Stars;