import React, {useEffect, useState} from 'react';

import {instance} from '../api';

const ReviewsContext = React.createContext();

export default ReviewsContext;

export function reviewsProvider(Component) {
    return props => {

        const [reviews, setReviews] = useState([]);
        const [isModalReviewShow, setIsModalReviewShow] = useState(false);
        const [isReviewCompleted, setIsReviewCompleted] = useState(false);
        const [formErrors, setFormErrors] = useState({});

        const state = {
            reviews,
            isModalReviewShow,
            isReviewCompleted,
            formErrors
        };

        useEffect(() => {
            const getReviews = async () => {
                const {data} = await instance.get('/reviews/?limit=10');
                setReviews(data);
            };
            getReviews();
        }, []);

        const actions = {
            getReviews: async url => {
                const {data} = await instance.get(url);
                if (data.results) {
                    const newResults = [...reviews.results, ...data.results];
                    setReviews({...data, results: newResults});
                }
            },
            openModalReview: () => setIsModalReviewShow(true),
            closeModalReview: () => {
                setIsModalReviewShow(false);
                setIsReviewCompleted(false);
            },
            saveReview: data => {
                const formData = new FormData();
                formData.append('author', data['author']);
                formData.append('rate', data['rate']);
                formData.append('review', data['review']);
                data.photo && formData.append('photo', data['photo'], data.photo.name);
                instance.post('/reviews/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((response) => {
                    setIsReviewCompleted(true);
                }).catch((error) => {
                    setFormErrors(error.response.data);
                });
            }
        };

        return (
            <ReviewsContext.Provider value={{...state, actions}}>
                <Component {...props}/>
            </ReviewsContext.Provider>
        );
    }
}
