import React, {useContext} from 'react';

import GlobalContext from '../../context/GlobalContext';


export default function Cart() {
    const {cart, actions} = useContext(GlobalContext);

    let msg = <span style={{lineHeight: '100px'}}>Корзина пуста.</span>;
    let cls = 'cart text-right';

    if (cart && cart.length) {
        const sum = cart.reduce((sum, item) => sum + parseInt(item.price) * item.count, 0);
        cls = cart.length === 1 ? `${cls} bottle1` : `${cls} bottle2`;
        msg = <span>
            В корзине товаров: {cart.length}<br/>
            на <span className="price">{sum} руб</span><br/>
            <button className='link' onClick={actions.showModalOrder}>Оформить заказ</button>
        </span>;
    }

    return <div className={cls}>{msg}</div>;
}