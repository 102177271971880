import React, {useState} from 'react';
import ReactImageMagnify from 'react-image-magnify';

export default function CatMagnifyImage({src}) {
    const [imgSize, setImgSize] = useState({});

    return <ReactImageMagnify hintTextMouse="Наведите для увеличения" hintTextTouch="Нажмите для увеличения" isHintEnabled style={{zIndex: 2000}}
      smallImage={{
          isFluidWidth: true,
          src: src,
          onLoad: ({target}) => {
              const {width, height} = target;
              setImgSize({width: width * 2, height: height * 2});
          }
      }}
      largeImage={{
          src: src,
          width: imgSize.width || 0,
          height: imgSize.height || 0,
      }}
    />;
}