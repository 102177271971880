import React, {useState} from 'react';

import {instance} from '../api';

const GlobalContext = React.createContext();

export default GlobalContext;

export function GlobalProvider(props) {
    const [cart, setCart] = useState([]);
    const [product, setProduct] = useState({});
    const [customer, setCustomer] = useState({});
    const [isModalAddShow, setIsModalAddShow] = useState(false);
    const [isModalOrderShow, setIsModalOrderShow] = useState(false);
    const [isModalDetailShow, setIsModalDetailShow] = useState(false);
    const [isOrderCompleted, setIsOrderCompleted] = useState(false);

    const state = {cart, product, customer, isModalAddShow, isModalOrderShow, isModalDetailShow, isOrderCompleted};

    const actions = {
        addToCart: price => {
            const index = cart.findIndex(obj => obj.id === price.id && obj.taste === price.taste);
            let newCart = [];
            setCart(oldCart => {
                if (index === -1) {
                    price.count = 1;
                    newCart = [...oldCart, price]
                } else {
                    newCart = [...oldCart.slice(0, index),
                        {...oldCart[index], count: ++oldCart[index].count},
                        ...oldCart.slice(index + 1)
                    ]
                }
                setIsModalAddShow(true);
                setIsOrderCompleted(false);
                return newCart;
            });
        },
        changeCartCount: (id, taste, count) => {
            const index = cart.findIndex(obj => obj.id === id && obj.taste === taste);
            if (index === -1) return false;
            setCart(oldCart => {
                const newCart = count > 0 ? [...oldCart.slice(0, index),
                    {...oldCart[index], count}, ...oldCart.slice(index + 1)
                ] : [...oldCart.slice(0, index), ...oldCart.slice(index + 1)];

                setIsModalOrderShow(newCart.length > 0);
                return newCart;
            });
        },
        showDetail: async productId => {
            await instance.get(`/cat/${productId}/`).then(response => {
                setProduct(response.data);
                setIsModalDetailShow(true);
            });
        },
        saveOrder: async data => {
            let details = [];
            cart.map(item => {
                const {id, count, taste} = item;
                details.push({price: id, taste, quantity: count});
                return true;
            });
            data.details = details;

            const {fio, phone, email} = data;
            const newCustomer = {fio, phone, email};
            localStorage.setItem('customer', JSON.stringify(newCustomer));

            await instance.post(`/orders/`, data).then(response => {
                let orders = JSON.parse(localStorage.getItem('orders') || '[]');
                orders.push(response.data.uuid);
                localStorage.setItem('orders', JSON.stringify(orders));

                setIsOrderCompleted(true);
                setCustomer(newCustomer);
                setCart([]);
            });
        },
        closeModalAdd: () => setIsModalAddShow(false),
        closeModalDetail: () => setIsModalDetailShow(false),
        closeModalOrder: () => setIsModalOrderShow(false),
        showModalOrder: () => setIsModalOrderShow(true)
    };

    return <GlobalContext.Provider value={{...state, actions}}>
        {props.children}
    </GlobalContext.Provider>;
}
