export function formatDate(date_string, ago=true) {
    const date = new Date(Date.parse(date_string));

    if (ago) {
        let now = new Date();
        let diff = now - date;
        let secDiff = Math.floor(diff / 1000);

        if (diff < 1000) return 'Сейчас';
        if (secDiff < 60) return `${parseInt(secDiff)} сек назад`;
        if (secDiff < 3600) return `${parseInt(secDiff / 60)} мин назад`;
    }

    // helper formatter
    const zeroFormat = unit => (unit < 10) ? `0${unit}` : unit;

    let day = zeroFormat(date.getDate());
    let month = zeroFormat(date.getMonth() + 1);
    let hours = zeroFormat(date.getHours());
    let minutes = zeroFormat(date.getMinutes());
    let year = String(date.getFullYear()).slice(2);

    return `${day}.${month}.${year} ${hours}:${minutes}`;
}
