import React, {useContext, Fragment} from 'react';
import Modal from 'react-bootstrap/Modal';
import InputMask from 'react-input-mask';
import {Formik} from 'formik';
import Button from 'react-bootstrap/Button';

import GlobalContext from '../../context/GlobalContext';
import SettingsContext from '../../context/SettingsContext';

import OrderCountInput from './OrderCountInput'


export default function OrderModal() {
    const {cart, isModalOrderShow, isOrderCompleted, customer, actions} = useContext(GlobalContext);
    const {settings} = useContext(SettingsContext);

    const {changeCartCount, closeModalOrder, saveOrder} = actions;

    const form_customer = customer !== {} ? JSON.parse(localStorage.getItem('customer') || JSON.stringify({
        fio: '',
        phone: '',
        email: ''
    })) : {};

    const sum = cart.reduce((sum, item) => sum + parseInt(item.price) * item.count, 0);

    const deleteHandler = item => () => {
        changeCartCount(item.id, item.taste, 0);
    };

    const completedModal = <Fragment>
        <Modal.Body>
            <div className="row">
                <div className="col-sm-12">
                    <p>{settings && settings.text_after_order}</p>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={closeModalOrder}>Закрыть</Button>
        </Modal.Footer>
    </Fragment>;

    const form = <Formik
        initialValues={{
            fio: form_customer.fio,
            email: form_customer.email,
            phone: form_customer.phone,
            description: ''
        }}
        validate={values => {
            let errors = {};
            if (!values.fio) {
                errors.fio = 'Поле не должно быть пустым';
            }
            if (!values.email) {
                errors.email = 'Поле не должно быть пустым';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Неправильный e-mail';
            }
            if (!values.phone) {
                errors.phone = 'Поле не должно быть пустым';
            } else if (!/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(values.phone)) {
                errors.phone = 'Неправильный номер телефона';
            }
            return errors;
        }}
        onSubmit={(values, {setSubmitting}) => {
            setTimeout(() => {
                saveOrder(values);
                setSubmitting(false);
            }, 1);
        }}
    >
        {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-12">
                            <p>Вы можете изменить состав заказа и отправить его.</p>
                            <div className="table-responsive">
                                <table className="table table-hover table-bordered">
                                    <tbody>
                                    <tr>
                                        <th>Вкусняшки</th>
                                        <th>Цена</th>
                                        <th>Количество</th>
                                        <th>Сумма</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                    {cart.map(item => <tr key={item.id + '/' + item.taste}>
                                        <td>{item.item_name} / {item.name} / {item.taste}</td>
                                        <td align="right" className="price">{parseInt(item.price)}</td>
                                        <td align="center">
                                            <OrderCountInput item={item} changeCartCount={changeCartCount}/>
                                        </td>
                                        <td align="right"
                                            className="price">{parseInt(item.price) * item.count}</td>
                                        <td>
                                            <button className="link" onClick={deleteHandler(item)}>
                                                удалить
                                            </button>
                                        </td>
                                    </tr>)}
                                    <tr>
                                        <th colSpan='3'>Итого, руб:</th>
                                        <td className="price" align="right">{sum}</td>
                                        <td/>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <h5>Данные покупателя</h5>
                            <hr/>

                            <div className="form-group row">
                                <label htmlFor="fio" className="col-sm-2 col-form-label">Ваше
                                    имя:</label>
                                <div className="col-sm-10">
                                    <input type="text"
                                           className="form-control"
                                           id="fio"
                                           placeholder="ФИО"
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.fio}
                                    />
                                    {errors.fio && touched.fio && <p className="error">{errors.fio}</p>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="phone" className="col-sm-2 col-form-label">Номер
                                    телефона:</label>
                                <div className="col-sm-10">
                                    <InputMask mask="+7 (999) 999-99-99" className="form-control"
                                               id="phone"
                                               placeholder="Номер телефона"
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               value={values.phone}
                                    />
                                    {errors.phone && touched.phone &&
                                    <p className="error">{errors.phone}</p>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="email"
                                       className="col-sm-2 col-form-label">E-mail:</label>
                                <div className="col-sm-10">
                                    <input type="email" className="form-control" id="email"
                                           placeholder="E-mail"
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.email}
                                    />
                                    {errors.email && touched.email &&
                                    <p className="error">{errors.email}</p>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="description" className="col-sm-2 col-form-label">
                                    Комментарий к заказу:
                                </label>
                                <div className="col-sm-10">
                                        <textarea className="form-control" id="description"
                                                  placeholder="Напишите здесь ваши пожелания к заказу."
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  value={values.description}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={closeModalOrder}>Продолжить покупки</Button>
                    <Button className='btn-success' type="submit" disabled={isSubmitting}>
                        Подтвердить заказ
                    </Button>
                </Modal.Footer>
            </form>
        )}
    </Formik>;

    return (
        <Modal
            show={isModalOrderShow}
            onHide={closeModalOrder}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span className="pink">Ваш заказ</span>
                </Modal.Title>
            </Modal.Header>
            {isOrderCompleted ? completedModal : form}
        </Modal>
    );
}
