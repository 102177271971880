import React, {useContext, Fragment} from 'react';
import Modal from 'react-bootstrap/Modal';
import {Formik} from 'formik';
import Button from 'react-bootstrap/Button';

import ReviewContext from '../../context/ReviewsContext';


export default function ReviewModal() {
    const {isModalReviewShow, isReviewCompleted, formErrors, actions} = useContext(ReviewContext);
    const {closeModalReview, saveReview} = actions;

    const formCustomer = JSON.parse(localStorage.getItem('customer') || JSON.stringify({fio: ''}));

    const completedModal = <Fragment>
        <Modal.Body>
            <div className="row">
                <div className="col-sm-12">
                    <p>Ваш отзыв отправлен! Спасибо! Возможно, вскоре он появится на сайте.</p>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={closeModalReview}>Закрыть</Button>
        </Modal.Footer>
    </Fragment>;

    const form = <Formik
        initialValues={{
            author: formCustomer.fio,
            rate: 5,
            photo: null,
            review: ''
        }}
        validate={values => {
            let errors = {}
            if (!values.author) {
                errors.author = 'Поле "Ваше имя" не должно быть пустым';
            }
            if (!values.rate) {
                errors.rate = 'Вы должны поставить оценку';
            }
            if (!values.review) {
                errors.review = 'Поле "Ваш отзыв" не должно быть пустым';
            }
            return errors;
        }}
        onSubmit={(values, {setSubmitting}) => {
            setTimeout(() => {
                saveReview(values);
                setSubmitting(false);
            }, 1);
        }}
    >
        {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue}) => {
            console.log(isSubmitting);
            return <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="author" className="col-sm-2 col-form-label">Ваше
                                    имя:</label>
                                <div className="col-sm-10">
                                    <input type="text"
                                           className="form-control"
                                           id="author"
                                           placeholder="Ваше имя"
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.author}
                                    />
                                    {errors.author && touched.author && <p className="error">{errors.author}</p>}
                                    {formErrors.author && <p className="error">{formErrors.author[0]}</p>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="rate" className="col-sm-2 col-form-label">Оценка от одного до
                                    пяти:</label>
                                <div className="col-sm-10">
                                    <input className="form-control"
                                           id="rate"
                                           placeholder="1-5"
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.rate}
                                    />
                                    {errors.rate && touched.rate && <p className="error">{errors.rate}</p>}
                                    {formErrors.rate && <p className="error">{formErrors.rate[0]}</p>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="photo"
                                       className="col-sm-2 col-form-label">Ваше фото:</label>
                                <div className="col-sm-10">
                                    <input type="file" id="photo"
                                           onChange={event => {
                                               setFieldValue('photo', event.currentTarget.files[0]);
                                           }}
                                           onBlur={handleBlur}
                                    />
                                    {errors.photo && touched.photo && <p className="error">{errors.photo}</p>}
                                    {formErrors.photo && <p className="error">{formErrors.photo[0]}</p>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="review" className="col-sm-2 col-form-label">
                                    Ваш отзыв:
                                </label>
                                <div className="col-sm-10">
                                        <textarea className="form-control" id="review"
                                                  placeholder="Напишите здесь ваш отзыв."
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  value={values.review}
                                        />
                                    {errors.review && touched.review && <p className="error">{errors.review}</p>}
                                    {formErrors.review && <p className="error">{formErrors.review[0]}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={closeModalReview}>Отмена</Button>
                    <Button className='btn-success' type="submit" disabled={isSubmitting}>
                        Отправить отзыв
                    </Button>
                </Modal.Footer>
            </form>
        }}
    </Formik>;

    return (
        <Modal
            show={isModalReviewShow}
            onHide={closeModalReview}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span className="pink">Ваш отзыв</span>
                </Modal.Title>
            </Modal.Header>
            {isReviewCompleted ? completedModal : form}
        </Modal>
    );
}
