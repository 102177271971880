import React, {useContext} from 'react';

import ReviewsContext, {reviewsProvider} from '../context/ReviewsContext';

import Head from '../components/common/Head';
import Slider from '../components/common/Slider';
import Tail from '../components/common/Tail';
import ReviewList from '../components/reviews/ReviewsList';
import ReviewModal from '../components/reviews/ReviewModal';


const Review = () => {

    const {actions} = useContext(ReviewsContext);

    return <React.Fragment>
        <Head/>
        <Slider/>
        <div className="container">
            <div className="row mb-5">
                <div className="col-6">
                    <h1>Отзывы</h1>
                </div>
                <div className="col-6 text-right mt-2">
                    <button className="btn btn-primary" onClick={actions.openModalReview}>Отправить отзыв</button>
                </div>
            </div>
            <ReviewList/>
        </div>
        <ReviewModal/>
        <Tail/>
    </React.Fragment>
};

export default reviewsProvider(Review);
