import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {GlobalProvider} from '../context/GlobalContext';
import {SettingsProvider} from '../context/SettingsContext';

import CartModal from '../components/cart/CartModal';
import OrderModal from '../components/order/OrderModal';
import CatDetailModal from '../components/cat/CatDetailModal';

import HomePage from './HomePage';
import ReviewPage from './ReviewPage';
import OrdersListPage from './OrdersListPage';
import OrderPage from './OrderPage';


export default function App() {
    return (
        <GlobalProvider>
            <SettingsProvider>
                <Switch>
                    <Route exact path="/" component={HomePage}/>
                    <Route path="/review" component={ReviewPage}/>
                    <Route path="/orders/:uuid" component={OrderPage}/>
                    <Route path="/orders" component={OrdersListPage}/>
                </Switch>
                <CartModal/>
                <CatDetailModal/>
                <OrderModal/>
            </SettingsProvider>
        </GlobalProvider>
    )
}