import React, {Fragment, useState, useEffect} from 'react';

import {instance} from '../api';
import {formatDate} from '../utils/date';

import Tail from '../components/common/Tail';


export default function OrderPage(props) {
    const initialState = {
        order: {}
    };
    const [state, setState] = useState(initialState);
    const {order} = state;

    useEffect(() => {
        const getOrder = async () => {
            const {data} = await instance.get(`/orders/${props.match.params.uuid}`);
            setState({order: data})
        };
        getOrder();
    }, [props.match.params.uuid]);

    return <Fragment>

        <div className="container mt-5">
            <div className="row">
                {Object.keys(order).length !== 0 ? <div className="col">

                    <table>
                        <tbody>
                        <tr>
                            <th width="200">Покупатель:</th>
                            <td>{order.fio}</td>
                        </tr>
                        <tr>
                            <th>Телефон:</th>
                            <td>{order.phone}</td>
                        </tr>
                        <tr>
                            <th>E-mail:</th>
                            <td>{order.email}</td>
                        </tr>
                        <tr>
                            <th>Комментарий к заказу:</th>
                            <td>{order.description}</td>
                        </tr>
                        <tr>
                            <th>Статус:</th>
                            <td>{order.status}</td>
                        </tr>
                        </tbody>
                    </table>

                    <h2 className="mt-5 mb-5 text-center">Заказ от {formatDate(order.date_order, false)}</h2>

                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th width="50%">Товар</th>
                            <th className="text-right">Цена</th>
                            <th className="text-center">Количество</th>
                            <th className="text-right">Сумма</th>
                        </tr>
                        </thead>
                        <tbody>
                        {order.details.map((item, index) => <tr key={index}>
                            <td>{item.name} / {item.taste}</td>
                            <td className="text-right">{parseInt(item.price_value)}</td>
                            <td className="text-center">{item.quantity}</td>
                            <td className="text-right">{parseInt(item.price_value * item.quantity)}</td>
                        </tr>)}
                        <tr>
                            <th colSpan="3">Итого:</th>
                            <td className="text-right">{order.total}</td>
                        </tr>
                        </tbody>
                    </table>

                </div> : <div className="alert alert-danger w-100">Заказ не найден</div>}
            </div>
        </div>
        <Tail/>
    </Fragment>
}