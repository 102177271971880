import React, {useState, useEffect} from 'react';

import {instance} from '../api';

const SettingsContext = React.createContext();

export default SettingsContext;

export function SettingsProvider(props) {
    const [state, setState] = useState({});

    useEffect(() => {
        const getSettings = async () => {
            const {data} = await instance.get(`/settings/`);
            setState({settings: data});
        };
        getSettings();
    }, []);

    return (
        <SettingsContext.Provider value={{...state}}>
            {props.children}
        </SettingsContext.Provider>
    );
}
