import React, {useContext} from 'react';
import {Link} from 'react-router-dom';

import SettingsContext from '../../context/SettingsContext';


const Tail = () => {
    const {settings} = useContext(SettingsContext);

    return <div className="tail">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <p>{settings && settings.tail_text}</p>
                </div>
                <div className="col-md-6 text-right">
                    <ul className="list-inline">
                        <li className="list-inline-item"><Link to="/">Каталог товаров</Link></li>
                        <li className="list-inline-item"><Link to="/review">Отзывы</Link></li>
                        <li className="list-inline-item"><Link to="/orders">История заказов</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
};

export default Tail;



