import React, {useContext} from 'react';
import Modal from 'react-bootstrap/Modal';

import GlobalContext from './../../context/GlobalContext';

import SelectPrice from "./SelectPrice";
import CatMagnifyImage from './CatMagnifyImage';


export default function CatDetailModal() {
    const {product, isModalDetailShow, actions} = useContext(GlobalContext);

    return (
        <Modal
            show={isModalDetailShow}
            onHide={actions.closeModalDetail}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {product.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-sm-6">
                        {product.image && <CatMagnifyImage src={product.image.full_size}/>}

                        {product.images && product.images.map(item => <div className='mb-3' key={item.id}><CatMagnifyImage
                            src={item.image.full_size} alt="" className="img-fluid"/></div>)}
                    </div>
                    <div className="col-sm-6">
                        <SelectPrice item={product} closeAfterAdd={true}/>
                        {product.description}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
