import React, {useContext} from 'react';

import GlobalContext from '../../context/GlobalContext';

import SelectPrice from './SelectPrice';


export default function CatListItem({item, listType}) {
    const {actions} = useContext(GlobalContext);

    const tile = <div className='col-sm-4'>
        <div className="item">
            <img src={item.image.thumbnail} alt={item.name} className='thumbnail img-fluid mb-3'
                 onClick={() => actions.showDetail(item.id)}/>
            <div className='mb-3'>
                {item.name}
            </div>
            <SelectPrice item={item}/>
        </div>
    </div>;

    const list =
        <div className="col-12 as-item" style={{borderBottom: "solid 1px #DDD"}}>
            <div className="row item">
                <div className="col-sm-2">
                    <img src={item.image.thumbnail} alt={item.name} className='thumbnail img-fluid mb-1'
                         onClick={() => actions.showDetail(item.id)}/>
                </div>
                <div className="col-sm-6">
                    <div className='mb-3'>{item.name}</div>
                </div>
                <div className="col-sm-4">
                    <SelectPrice item={item}/>
                </div>
            </div>
        </div>;

    return listType === 'tile' ? tile : list;
}
