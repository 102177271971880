import React, {useState, useContext} from 'react';

import SettingsContext from '../../context/SettingsContext';

import CatListItem from './CatListItem';


const CatList = ({catList}) => {
    const {settings} = useContext(SettingsContext);

    const [listType, setListType] = useState(localStorage.getItem('listType') || 'tile');

    const handlerChangeListType = () => {
        const newListType = listType === 'tile' ? 'list': 'tile';
        setListType(newListType);
        localStorage.setItem('listType', newListType);
    };

    return <div className="container">
        <div className="row as-item">
            <div className="col">
                <div className="alert alert-success">
                    <h5>{settings && settings.help_text}</h5>
                </div>
            </div>
        </div>
        <div className="row as-item">
            <div className="col-12 text-right d-none d-sm-block">
                <button className={`btn btn-${listType}`} onClick={handlerChangeListType}>
                </button>
            </div>
        </div>
        <div className="row">
            {catList.map(item => <CatListItem key={item.id} item={item} listType={listType}/>)}
        </div>
    </div>
};

export default CatList;