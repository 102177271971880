import React, {useState, useEffect} from 'react';
import Slick from "react-slick";
import {instance} from '../../api';


export default function Slider() {

    const sliderSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 300,
        centerMode: true,
        centerPadding: 0,
        arrows: false
    };

    const [slides, setSlides] = useState([]);

    useEffect(() => {
        const getSlides = async () => {
            const {data} = await instance.get(`/slider/`);
            setSlides(data)
        };
        getSlides();
    }, []);

    return <div className='slider d-none d-sm-block mb-5'>
        <Slick {...sliderSettings}>
            {slides && slides.map(item => <div key={item.id}><img src={item.image.full_size} alt="" className="img-fluid"/></div>)}
        </Slick>
    </div>
}
