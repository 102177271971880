import no_ava from '../../img/no-avatar.png';

import React, {useState, useEffect} from 'react';
import Slick from "react-slick";
import {Link} from 'react-router-dom';

import {instance} from '../../api';
import {formatDate} from '../../utils/date';
import Stars from './Stars';


export default function ReviewSlider() {
    const sliderSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        speed: 300,
        arrows: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    const [slides, setSlides] = useState([]);

    useEffect(() => {
        const getSlides = async () => {
            const {data} = await instance.get('/reviews/?limit=6');
            setSlides(data.results)
        };
        getSlides();
    }, []);

    return <section className="reviews">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2>Отзывы</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className='slider '>
                        <Slick {...sliderSettings}>
                            {slides && slides.map(item => <div key={item.id}>
                                <div className="slider-item mb-5">
                                    <p>
                                        <img src={item.photo.thumbnail ? item.photo.thumbnail : no_ava } alt="" className="round-thumbnail"/>
                                    </p>
                                    <p>{item.review.slice(0, 100)}{item.review.length < 100 || '...'}</p>
                                    <p><Stars rate={item.rate}/></p>
                                    <p className="reviewer">{item.author} | {formatDate(item.date_published)}</p>
                                </div>
                            </div>)}
                        </Slick>
                    </div>
                    <div className="text-center">
                        <Link to="/review" className="btn btn-primary mb-4">Все отзывы</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>;
}

