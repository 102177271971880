import React, {useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import GlobalContext from '../../context/GlobalContext';


export default function CartModal() {
    const {cart, isModalAddShow, actions} = useContext(GlobalContext);
    const {closeModalAdd, showModalOrder} = actions;

    let cls = 'cart';
    let msg = '';
    if (cart && cart.length) {
        const sum = cart.reduce((sum, item) => sum + parseInt(item.price) * item.count, 0);
        cls = cart.length === 1 ? `${cls} bottle1` : `${cls} bottle2`;
        msg = <span>
            В корзине товаров: {cart.length}<br/>
            на <span className='price'>{sum} руб</span>
        </span>;
    }

    return (
        <Modal
            show={isModalAddShow}
            onHide={closeModalAdd}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Товар добавлен в корзину
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col">
                        <div className={cls}>{msg}</div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn-success' onClick={() => {
                    closeModalAdd();
                    showModalOrder();
                }}>Оформить</Button>
                <Button onClick={closeModalAdd}>Продолжить покупки</Button>
            </Modal.Footer>
        </Modal>
    );
}