import React, {Fragment, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import {instance} from '../api';
import {formatDate} from '../utils/date';

import Head from '../components/common/Head';
import Slider from '../components/common/Slider';
import Tail from '../components/common/Tail';


export default function OrdersListPage() {
    const initialState = {
        ordersList: []
    };
    const [state, setState] = useState(initialState);
    const {ordersList} = state;

    useEffect(() => {
        const uuids = JSON.parse(localStorage.getItem('orders') || '[]').reverse().slice(0, 10).join(',');
        const getOrdersList = async () => {
            const {data} = await instance.get(`/orders/?uuid__in=${uuids}`);
            setState({ordersList: data})
        };
        getOrdersList();
    }, []);

    return <Fragment>
        <Head/>
        <Slider/>
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="mb-4">История заказов *</h1>
                    {ordersList && ordersList.length !== 0 ? <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Дата заказа</th>
                            <th>Покупатель</th>
                            <th className="text-right">Сумма заказа</th>
                            <th className="text-center">Статус</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ordersList.map(item => <tr key={item.uuid}>
                            <td><Link to={`/orders/${item.uuid}`}>{formatDate(item.date_order)}</Link></td>
                            <td>{item.fio}</td>
                            <td className="text-right">{item.total}</td>
                            <td className="text-center"><span className="badge badge-success">{item.status}</span></td>
                        </tr>)}
                        </tbody>
                    </table> : <div className="alert alert-dark">У Вас пока нет сохраненных заказов. Желаем приятных покупок!</div> }
                    <p className="small">* - отображаются последние 10 заказов. Идентификаторы заказов сохраняются в локальное хранилище вашего браузера.</p>
                </div>
            </div>
        </div>
        <Tail/>
    </Fragment>
}