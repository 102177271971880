import React, {useContext} from 'react';

import SettingsContext from '../../context/SettingsContext';


const Phone = () => {
    const {settings} = useContext(SettingsContext);
    return <div className='phone'>{settings && settings.phone}</div>;
};

export default Phone;